<template>
    <div class="surface-0 align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden login-wrapper">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-10 lg:col-5 xl:col-5" style="border-radius:56px; padding:0.3rem; text-align: center;">
                <img src="layout/images/logos-juntos-recurso.png" alt="Image" class="login-logo-img" />
                <div class="w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="bienvenido-lbl">¡Bienvenid@!</div>
                        <span class="inicio-sesion-lbl">Inicio de Sesión</span>
                    </div>
                    <form action class="form" @submit.prevent="login">
                        <div class="w-full md:w-10 mx-auto">
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email" style="padding:1rem;" />  
                            <InputText id="password1" v-model="password" type="password" class="w-full mb-3" placeholder="Contraseña" style="padding:1rem;" />
                            <p v-if="error" class="error">
                                Has introducido mal el email o la contraseña.
                            </p>                   
                            <Button type="submit" class="p-3 ingresar-btn" label="Ingresar"></button>
                        </div>
                    </form>
                </div>
                <img src="layout/images/sello-recurso.png" alt="Image" class="login-calidad-img" />
            </div>
        </div>
        <div class="grid justify-content-center">
            <div class="login-footer">
                <div class="text-center login-footer-wrapper">
                    <div class="col-12">
                        <div class="desarrollo-lbl mb-3">Desarrollado por INV Agente de Seguros y de Fianzas SA de CV. © Todos los derechos reservados</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { login } from '@/auth/user';

export default {    
    data() {
        return {
            email: '',
            password: '',
            error: false,            
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {

        login(){
            var params = {
                user: this.email,
                password: this.password
            }

            login(params);
                                   
            this.email = "";
            this.password = "";            
        }
    }
}
</script>

<style scoped>
.login-wrapper {
    background-color: #191A33 !important;
}
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}

.bienvenido-lbl {
    color: #191A33;
    font-size: 24px;
    font-weight: bold;
}

.desarrollo-lbl {
    font-size: 12px;
    color: #fff;
}

.login-footer {
    position: absolute;
    bottom: 0px;
}

.login-footer-wrapper {
    display: inline-block;
}

.inicio-sesion-lbl {
    font-size: 13px;
    color: #05B8DD;
}

.ingresar-btn {
    background-color: #4E556F;
    color: #fff !important;
    font-size: 14px !important;
    padding: 0.5rem 2rem !important;
}

.login-logo-img {
    padding-top: 80px;
    width: 70%;
}

.login-calidad-img {
    padding-top: 40px;
    width: 60%;
}

.p-inputtext {
    padding: 5px 0 5px 15px !important;
}
</style>